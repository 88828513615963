import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAppContext } from "../../context/AppContext";

const PrivateRoute = ({ children, requiredRole }) => {
  const { authToken, userRole, loading } = useAppContext();
  const location = useLocation();

  if(loading){
    return <>Loading...</>;
  }

  if (!authToken) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  if (requiredRole && userRole !== requiredRole) {
    if (userRole === "user") {
      return <Navigate to="/" replace />;
    } else if (userRole === "admin") {
      return <Navigate to="/plans" replace />;
    }
  }

  return children;
};

export default PrivateRoute;
