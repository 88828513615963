import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import Userprofile from "./pages/UserProfile";
import Signup from "./Components/Auth/Signup";
import Layout from "./Components/Layout";
import ComposeEmail from "./pages/compose-email";
import Inbox from "./pages/Inbox";
import Sentitem from "./pages/sentitem";
import Trash from "./pages/trash";
import Starred from "./pages/starred";
import Archived from "./pages/archived";
import Singleinbox from "./pages/Inbox/SingleInbox";
import Login from "./Components/Auth/Login";
import { ToastContainer } from "react-toastify";
import PrivateRoute from "./Components/Routes/PrivateRoute";
import PublicRoute from "./Components/Routes/PublicRoute";
import { AppProvider } from "./context/AppContext";
import ForgotPassword from "./Components/Auth/ForgotPassword";
import ResetPassword from "./Components/Auth/ResetPassword";
import Drafts from "./pages/drafts";
import Domains from "./pages/domains";
import CustomEmail from "./pages/customemail";
import Plans from "./pages/plans";



const routes = [
  { path: "/register", element: <Signup />, isPublic: true },
  { path: "/login", element: <Login />, isPublic: true },
  { path: "/forgot-password", element: <ForgotPassword />, isPublic: true },
  { path: "/reset-password/:token", element: <ResetPassword />, isPublic: true },
  // { path: "/forgot-password", element: <ForgotPassword />, isPublic: true },
  // { path: "/reset-password/:token", element: <ResetPassword />, isPublic: true },
  { path: "/", element: <Inbox />, role: "user" },
  { path: "/profile", element: <Userprofile /> },
  { path: "/drafts", element: <Drafts />, role: "user" },
  { path: "/compose", element: <ComposeEmail />, role: "user" },
  { path: "/inbox", element: <Inbox />, role: "user" },
  { path: "/starred", element: <Starred />, role: "user" },
  { path: "/archived", element: <Archived />, role: "user" },
  { path: "/sentitem", element: <Sentitem />, role: "user" },
  { path: "/domains", element: <Domains />, role: "admin" },
  { path: "/custom-email", element: <CustomEmail />, role: "admin" },
  { path: "/trash", element: <Trash /> },
  // { path: "/inbox-single/:id", element: <Singleinbox /> },
  { path: "/singleinbox/:id", element: <Singleinbox />, role: "user" },
  { path: "/plans", element: <Plans />,  role: "admin" },
];

function App() {
  return (
    <AppProvider>
      <ToastContainer />
      <BrowserRouter>
        <Layout>
          <Routes>
            {routes.map((route) => {
              const RouteComponent = route.isPublic ? PublicRoute : PrivateRoute;
              return (
                <Route
                  key={route.path}
                  path={route.path}
                  element={
                    <RouteComponent requiredRole={route.role}>
                      {route.element}
                    </RouteComponent>
                  }
                />
              );
            })}
          </Routes>
        </Layout>
      </BrowserRouter>
    </AppProvider>
  );
}

export default App;
