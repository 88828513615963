import React, { useEffect, useState } from "react";
import { CgLink } from "react-icons/cg";
import { HiOutlineDotsVertical } from "react-icons/hi";
import { IoMdRefresh } from "react-icons/io";
import { IoTimeOutline, IoTrashOutline } from "react-icons/io5";
import {
  MdArchive,
  MdOutlineArchive,
  MdOutlineCheckBoxOutlineBlank,
  MdOutlineChevronLeft,
  MdOutlineChevronRight,
  MdOutlineStarBorderPurple500,
} from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import useCheckboxHandler from "../../Components/hooks/useCheckboxHandler";
import {
  getStarredMails,
  moveAllEmailToTrash,
  moveToTrash,
  toggleArchiveStatus,
  toggleFavoriteStatus,
} from "../../Api/ExportApi";
import ToastHandle from "../../Components/helpers/ToastMessage";
import { jwtDecode } from "jwt-decode";
import MarkAllRead from "../../Components/ui/MarkAllRead";
import { formatDate } from "../../Components/helpers/FormatDate";
const Starred = () => {
  const [starredEmails, setStarredEmails] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemPerPage] = useState(10);
  const [isLoading, setLoading] = useState(false);
  const [userId, setUserId] = useState(null);

  const {
    selectAllCheckbox,
    selectedItems,
    setSelectAllCheckbox,
    setSelectedItems,
    handleCheckboxClick,
  } = useCheckboxHandler(starredEmails);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      const token = localStorage.getItem("token");
      if (token) {
        try {
          const decodedToken = jwtDecode(token);
          const userId = decodedToken.userId;

          // const resp = await userDatabyid(userId);
          setUserId(userId);
        } catch (err) {
          console.error("Error fetching user data:", err.message);
        }
      } else {
        console.log("No token found");
      }
    };

    fetchUserData();
  }, []);

  const fetchStarredEmails = async (page) => {
    setLoading(true);
    try {
      const resp = await getStarredMails(page);

      setStarredEmails(resp.data.emails);
      setTotalItems(resp?.data?.total || 0);
      setItemPerPage(resp?.data?.limit);
      setSelectAllCheckbox(false);
      setSelectedItems([]);
    } catch (err) {
      // toast.error("Failed to fetch emails");
      console.error("Error fetching incoming emails:", err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchStarredEmails(currentPage);
  }, [currentPage]);

  const handleToggleFavoriteStatus = async (emailId) => {
    try {
      await toggleFavoriteStatus(emailId);
      ToastHandle("Email favorite status updated", "success");
      fetchStarredEmails(currentPage);
    } catch (error) {
      ToastHandle("Failed to update favorite status", "danger");
      console.error("Error updating favorite status:", error);
    }
  };

  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const startItem = (currentPage - 1) * itemsPerPage + 1;
  const endItem = Math.min(currentPage * itemsPerPage, totalItems);

  const handlePageChange = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  const handleRefresh = () => {
    fetchStarredEmails(currentPage);
  };

  const handleToggleArchiveStatus = async (emailId) => {
    try {
      const resp = await toggleArchiveStatus(emailId);
      if (
        resp.message === "Email added to archive" ||
        "Email removed from archive"
      ) {
        fetchStarredEmails(currentPage);
        ToastHandle("Email archive status updated", "success");
      } else {
        ToastHandle("Failed to update archive status", "danger");
      }
    } catch (error) {
      ToastHandle("Failed to update archive status", "danger");
      console.error("Error updating archive status:", error);
    }
  };


  const handleDeleteAll = async () => {
    try {
      await moveAllEmailToTrash([...selectedItems]);
      if (selectedItems.length === starredEmails.length) {
        if (currentPage > 1) {
          fetchStarredEmails(currentPage - 1);
        } else {
          fetchStarredEmails(currentPage);
        }
      }
    } catch (error) {
      ToastHandle("Failed to delete email.", "danger");
      console.error("Error deleting email:", error);
    }
  };


  const handleMoveToTrash = async (emailId, redirect) => {
    try {
      await moveToTrash(emailId);
      ToastHandle("Email moved to trash", "success");
      if (redirect) {
        navigate("/trash");
      } else {
        fetchStarredEmails(currentPage);
      }
    } catch (error) {
      ToastHandle("Failed to move email to trash", "danger");
      console.error("Error moving email to trash:", error);
    }
  };


  return (
    <>
      <div className="py-2">
        <div className="bg-white inbox-header flex flex-wrap items-center justify-between px-3 pb-2">
          <div className="flex items-center gap-3 relative">
          <span className="block mt-1">
              {/* <MdOutlineCheckBoxOutlineBlank className='text-xl' /> */}
              <input
                checked={selectAllCheckbox && starredEmails?.length > 0}
                type="checkbox"
                className="cursor-pointer"
                onChange={(e) => handleCheckboxClick(e, "toggle")}
              />
            </span>
            <div className="dropdown inline-block relative">
              <button className=" text-gray-700 font-semibold hover:bg-gray-200 text-gray-500 py-2 px-1 rounded inline-flex items-center">
                <svg
                  className="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />{" "}
                </svg>
              </button>
              <ul className="dropdown-menu absolute hidden text-gray-700 pt-1">
                <li
                  className="rounded-t bg-white hover:bg-gray-200 py-2 px-4 block whitespace-no-wrap"
                  onClick={(e) => handleCheckboxClick(e, "all")}
                >
                  All
                </li>
                <li
                  className="rounded-t bg-white hover:bg-gray-200 py-2 px-4 block whitespace-no-wrap"
                  onClick={(e) => handleCheckboxClick(e, "none")}
                >
                  None
                </li>
                <li
                  className="rounded-t bg-white hover:bg-gray-200 py-2 px-4 block whitespace-no-wrap"
                  onClick={(e) => handleCheckboxClick(e, "read", null, userId)}
                >
                  Read
                </li>
                <li
                  className="rounded-t bg-white hover:bg-gray-200 py-2 px-4 block whitespace-no-wrap"
                  onClick={(e) =>
                    handleCheckboxClick(e, "unread", null, userId)
                  }
                >
                  Unread
                </li>
                <li
                  className="rounded-t bg-white hover:bg-gray-200 py-2 px-4 block whitespace-no-wrap"
                  onClick={(e) =>
                    handleCheckboxClick(e, "starred", null, userId)
                  }
                >
                  Starred
                </li>
              </ul>
            </div>
            <button onClick={handleRefresh}>
              <IoMdRefresh className="text-2xl  hover:bg-gray-200 text-gray-500 rounded" />
            </button>
            <MarkAllRead
              emails={starredEmails}
              selectedItems={selectedItems}
              userId={userId}
              currentPage={currentPage}
              refetchEmails={fetchStarredEmails}
              selectAllCheckbox={selectAllCheckbox}
            />
             {selectedItems?.length > 1 && (
              <button
                className="font-[500] text-gray-400 hover:bg-gray-200 p-1 rounded-sm"
                onClick={handleDeleteAll}
              >
               <IoTrashOutline size={24} className="text-xl text-gray-400 hidden md:block" />
              </button>
            )}
          </div>
          <div className="flex items-center gap-3">
            {/* <p className="text-gray-500 font-normal text-base hover:bg-gray-200 p-2 rounded">
              <Link to="">1-50 of 51-100</Link>
            </p> */}
             <p className="text-gray-500 font-normal text-base hover:bg-gray-200 p-2 rounded">
              {startItem}-{endItem} of {totalItems}
            </p>
            <div className="flex items-center">
              <button
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
              >
                <MdOutlineChevronLeft className="text-gray-800 text-xl hover:bg-gray-200 rounded" />
              </button>
              <button
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
              >
                <MdOutlineChevronRight className="text-gray-800 text-xl hover:bg-gray-200 rounded" />
              </button>
            </div>
          </div>
        </div>
        <div className="inbox-body border-t ">
          <div className="inbox-body-inner">
            {starredEmails?.length > 0 ? (
              <>
                {starredEmails?.map((email) => (
                  <div
                    className={`cursor-pointer bg-white item flex item-center gap-3 border-b  p-3 hover:shadow-lg  ${email?.readBy && email?.readBy?.includes(userId) ? '!bg-[#e4f3ff] hover:!shadow-none' :''}`}
                    key={email?._id}
                  >
                    <div className="flex items-center  gap-2">
                      <div className="gap-2 flex items-center">
                        <div className="inline-flex items-center">
                          <label
                            className="relative flex items-center rounded-full cursor-pointer"
                            htmlFor="check"
                          >
                            <input
                              type="checkbox"
                              className=" before:content[''] peer relative h-4 w-4 cursor-pointer appearance-none rounded border-2 border-gray-500 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-gray-900 checked:bg-transparent checked:before:bg-transparent hover:before:opacity-10"
                              id="check"
                              checked={
                                selectAllCheckbox ||
                                selectedItems.includes(email?._id)
                              }
                              name="checkbox"
                              onChange={(e) =>
                                handleCheckboxClick(e, "single", email?._id)
                              }
                            />
                            <span className="absolute text-gray-600 transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-3 w-3"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                                stroke="currentColor"
                                stroke-width="1"
                              >
                                <path
                                  fill-rule="evenodd"
                                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                  clip-rule="evenodd"
                                ></path>
                              </svg>
                            </span>
                          </label>
                        </div>
                        <button
                          onClick={(event) => {
                            event.stopPropagation();
                            handleToggleFavoriteStatus(email._id);
                            console.log(email.starredBy);
                          }}
                        >
                          <MdOutlineStarBorderPurple500
                            className={`text-xl text-yellow-500 hidden md:block `}
                          />
                        </button>
                      </div>
                    </div>

                    <div className="flex items-center justify-between w-full">
                      <div
                        className="flex items-center w-full"
                        onClick={() => {
                          if(email?.replyEmailId){
                            navigate(`/singleinbox/${email?.replyEmailId}`)
                          }else{
                            navigate(`/singleinbox/${email?._id}`)
                          }
                        }}
                      >
                        <div className="">
                          <p className="font-medium text-[14px] text-gray-700">
                          {email.subject ? email.subject : "(No subject)"}
                          </p>
                          <p
                            className="font-normal text-[12px] text-gray-600"
                            dangerouslySetInnerHTML={{ __html: email?.body?.slice(0, 40) }}
                          />
                        </div>
                      </div>

                      <div className="gap-2 flex items-center relative">
                        <div className="absolute top-0 md:right-24 right-16 flex items-center gap-2">
                        <button
                          onClick={() => handleToggleArchiveStatus(email?._id)}
                        >
                          {email?.archivedBy &&
                          email?.archivedBy?.includes(userId) ? (
                            <MdArchive size={20} />
                          ) : (
                            <MdOutlineArchive className="text-xl text-gray-400 hidden md:block" />
                          )}
                        </button>
                        <button
                          onClick={() => handleMoveToTrash(email?._id, false)}
                        >
                          <IoTrashOutline className="text-xl text-gray-400 hidden md:block" />
                        </button>
                        <button
                          onClick={(event) => {
                            event.stopPropagation();
                            handleToggleFavoriteStatus(email._id);
                          }}
                        >
                          <MdOutlineStarBorderPurple500
                            className={`text-xl ${
                              email?.starredBy &&
                              email?.starredBy?.includes(userId)
                                ? "text-yellow-500"
                                : "text-gray-400"
                            }   md:hidden block `}
                          />
                        </button>
                        </div>
                        <button className="flex items-center whitespace-nowrap gap-2">
                        <span>{formatDate(email?.createdAt)}</span>
                        <IoTimeOutline className="text-xl text-gray-400 hidden md:block" />
                        </button>
                      </div>
                    </div>
                  </div>
                ))}{" "}
              </>
            ) : (
              <div className="w-fill flex justify-center items-center h-[50vh]">
                <div colSpan="3" className="text-center py-4">
                  No emails found
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Starred;
