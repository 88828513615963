import { jwtDecode } from 'jwt-decode';
import React, { createContext, useState, useContext, useEffect } from 'react';
import { userDatabyid } from '../Api/ExportApi';

export const AppContext = createContext();

export const AppProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [authToken, setAuthToken] = useState(null);
    const [userRole, setUserRole] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {

        const fetchUserData = async () => {
            const token = localStorage.getItem("token");
            if (token) {
              try {
                const decodedToken = jwtDecode(token);
                const userId = decodedToken.userId;
      
                const resp = await userDatabyid(userId);                
                login(resp?.data?.user, token, resp?.data?.user?.role);
                setLoading(false);
              } catch (err) {
                console.error("Error fetching user data:", err.message);
              }
            } else {
              console.log("No token found");
            }
          };
      
        fetchUserData();
    }, [authToken]);

    const login = (userData, token, role) => {
        setUser(userData);
        setAuthToken(token);
        setUserRole(role);
    };

    const logout = () => {
        setUser(null);
        setAuthToken(null);
        setUserRole(null);
    };

    return (
        <AppContext.Provider value={{ user, authToken, userRole, login, logout, loading }}>
            {children}
        </AppContext.Provider>
    );
};

export const useAppContext = () => useContext(AppContext);
