import React, { useEffect, useRef, useState } from "react";
import { HiOutlineDotsVertical } from "react-icons/hi";
import { IoMdRefresh, IoMdStarOutline } from "react-icons/io";
import {
  IoAttach,
  IoReturnUpBack,
  IoTimeOutline,
  IoTrashOutline,
} from "react-icons/io5";
import {
  MdArchive,
  MdDownload,
  MdOutlineArchive,
  MdOutlineCheckBoxOutlineBlank,
  MdOutlineChevronLeft,
  MdOutlineChevronRight,
  MdOutlineMoreVert,
  MdOutlineReport,
  MdOutlineStarBorderPurple500,
} from "react-icons/md";
import "./style.css";
import { TbArrowBackUp, TbArrowForwardUp } from "react-icons/tb";
import {
  BiChevronLeft,
  BiChevronRight,
  BiPrinter,
  BiReply,
} from "react-icons/bi";
import { RxCross2, RxOpenInNewWindow } from "react-icons/rx";
import User from "../../images/user.png";
import Imagebox from "../../images/image-box.png";
import Placeholder from "../../images/placeholder.jpg";
import { TiArrowForwardOutline } from "react-icons/ti";
import { BsMailbox, BsReply, BsTrash2 } from "react-icons/bs";
import { useNavigate, useParams } from "react-router-dom";
import {
  emailRead,
  emailUnread,
  getEmailReplies,
  getIncomingEmailbyId,
  moveToTrash,
  replyToEmail,
  toggleArchiveStatus,
  toggleFavoriteStatus,
  userDatabyid,
  verifyUserDomain,
} from "../../Api/ExportApi";
import { jwtDecode } from "jwt-decode";
import ToastHandle from "../../Components/helpers/ToastMessage";
import ReactQuill from "react-quill";
import { GoReply } from "react-icons/go";
import { saveAs } from "file-saver";
import { FaFile, FaFileImage, FaFilePdf } from "react-icons/fa";

const SingleInbox = () => {
  const { id } = useParams();
  const [email, setEmail] = useState();
  const [favoriteEmails, setFavoriteEmails] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [emailToTrash, setEmailToTrash] = useState(null);
  const [handleShowReply, setHandleShowReply] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userId, setUserId] = useState("");
  const [replies, setReplies] = useState([]);
  const [body, setBody] = useState("");
  const dropdownRefs = useRef({});
  const [openDropdownId, setOpenDropdownId] = useState(null);
  const [openParentDropdown, setOpenParentDropdown] = useState(false);
  const [attachments, setAttachments] = useState([]);
  const [userData, setUserData] = useState();

  const [toEmails, setToEmails] = useState(email?.to || []);
  const [ccEmails, setCcEmails] = useState(email?.cc || []);
  const [bccEmails, setBccEmails] = useState(email?.bcc || []);

  const fileInputRef = useRef(null);

  const navigate = useNavigate();

  const modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { header: "3" }],
      [{ list: "ordered" }, { list: "bullet" }],
      ["bold", "italic", "underline", "strike"],
      [{ align: [] }],
      [{ color: [] }, { background: [] }],
      [{ font: [] }],
      [{ size: [] }],
      ["clean"],
    ],
  };

  const formats = [
    "header",
    "font",
    "size",
    "list",
    "bullet",
    "indent",
    "bold",
    "italic",
    "underline",
    "strike",
    "color",
    "background",
    "align",
    "image",
    "video",
  ];

  // const handleImageUpload = async (file) => {
  //   try {
  //     const formData = new FormData();
  //     formData.append("file", file);

  //     const response = await fetch(
  //       "http://localhost:3000/api/mails/upload-photo",
  //       {
  //         method: "POST",
  //         body: formData,
  //       }
  //     );

  //     if (!response.ok) {
  //       throw new Error("Image upload failed");
  //     }

  //     const data = await response.json();
  //     return data.url; // Adjust according to your backend response
  //   } catch (error) {
  //     console.error("Image upload failed:", error);
  //     return null;
  //   }
  // };

  useEffect(() => {
    const url = window.location.href;

    const queryString = url.split("?")[1];
    if (queryString && queryString.includes("print")) {
      setTimeout(() => {
        handlePrint();
      }, 1000);
    }

    const fetchUserData = async () => {
      const token = localStorage.getItem("token");
      if (token) {
        try {
          const decodedToken = jwtDecode(token);
          const id = decodedToken.userId;
          const resp = await userDatabyid(id);
          setUserData(resp?.data?.user);
          setUserId(id);
        } catch (err) {
          console.error("Error fetching user data:", err.message);
        }
      } else {
        console.log("No token found");
      }
    };

    fetchUserData();

    const handleClickOutside = (event) => {
      if (
        dropdownRefs.current &&
        !Object.values(dropdownRefs.current).some(
          (ref) => ref && ref.contains(event.target)
        )
      ) {
        setOpenDropdownId(null);

        setOpenParentDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (userId && id) {
      fetchEmail();
      //   changeEmailReadStatus(id);
      fetchEmailReplies();
    }
  }, [userId, id]);

  const fetchEmail = async () => {
    try {
      const response = await getIncomingEmailbyId(id);
      setEmail(response.data);
      setToEmails(response.data?.to);
      setCcEmails(response.data?.cc);
      setBccEmails(response.data?.bcc);

      if (!response.data?.readBy?.includes(userId)) {
        changeEmailReadStatus(id);
      }
    } catch (err) {
      console.error("Error fetching email:", err);
    }
  };

  const fetchEmailReplies = async () => {
    try {
      const response = await getEmailReplies(id);
      setReplies(response.data);
    } catch (err) {
      console.error("Error fetching email replies:", err);
    }
  };

  const changeEmailReadStatus = async (id) => {
    try {
      await emailRead(id);
    } catch (err) {
      console.error("Error email read status:", err);
    }
  };

  const handleRemoveEmail = (type, index) => {
    if (type === "to") {
      setToEmails((prev) => prev.filter((_, i) => i !== index));
    } else if (type === "cc") {
      setCcEmails((prev) => prev.filter((_, i) => i !== index));
    } else if (type === "bcc") {
      setBccEmails((prev) => prev.filter((_, i) => i !== index));
    }
  };

  const handleAddEmail = (type, event) => {
    if (event.key === "Enter" && event.target.value) {
      const newEmail = event.target.value.trim();
      if (type === "to") {
        setToEmails((prev) => [...prev, newEmail]);
      } else if (type === "cc") {
        setCcEmails((prev) => [...prev, newEmail]);
      } else if (type === "bcc") {
        setBccEmails((prev) => [...prev, newEmail]);
      }
      event.target.value = "";
    }
  };

  function formatDate(dateString) {
    const date = new Date(dateString);

    const options = {
      month: "short",
      day: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    const formattedDate = date
      .toLocaleDateString("en-US", options)
      .replace(",", "");

    const now = new Date();
    const timeDifference = Math.abs(now - date);
    const daysAgo = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    const timeAgo = daysAgo === 0 ? "today" : `${daysAgo} days ago`;

    return `${formattedDate} (${timeAgo})`;
  }

  const displayDate = formatDate(email?.createdAt);

  const handleEmailReply = async () => {
    if (body.trim() !== "") {
      setLoading(true);

      let updatedToEmails = toEmails.filter(
        (emailAddress) => emailAddress !== userData.email
      );
      let updatedCcEmails = ccEmails.filter(
        (emailAddress) => emailAddress !== userData.email
      );

      if (ccEmails.length > 0 || bccEmails.length > 0) {
        updatedToEmails = [email.from];
        updatedCcEmails = [...new Set([...updatedCcEmails, ...toEmails])];
      }

      if (toEmails.length === 1 && toEmails[0] === userData.email) {
        updatedToEmails = [email.from];
      }

      
      if (toEmails.length === 1 && userData.email === email.from) {
        updatedToEmails = [...toEmails];
      }

      updatedCcEmails = updatedCcEmails.filter(
        (emailAddress) => !updatedToEmails.includes(emailAddress)
      );
  
      // const updatedBccEmails = bccEmails.filter(
      //   (emailAddress) => emailAddress !== userData.email
      // );

      const updatedBccEmails = [];

      const response = await replyToEmail(
        id,
        body,
        attachments,
        updatedToEmails,
        updatedCcEmails,
        updatedBccEmails
      );

      if (response.status === 201) {
        setLoading(false);
        setHandleShowReply(false);
        setBody("");
        fetchEmailReplies();
        setAttachments([]);
      } else {
        setLoading(false);
      }
    } else {
      alert("empty");
    }
  };

  const handleDropdownToggle = (id) => {
    setOpenDropdownId(openDropdownId === id ? null : id);
  };

  const handleReplyInput = (value) => {
    setBody(value);
  };

  const handleMarkUnread = async (id) => {
    try {
      const resp = await emailUnread(id);

      if (resp.status === 200) {
        ToastHandle("Email marked unread successfully!", "success");
      }
    } catch (error) {
      console.log(error);
      ToastHandle(error.response?.data?.message, "danger");
    }
  };

  const handleFileChange = (event) => {
    const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB in bytes
    const files = Array.from(event.target.files);

    // Filter files based on the size limit
    const validFiles = files.filter((file) => file.size <= MAX_FILE_SIZE);

    // Check for invalid files
    const invalidFiles = files.filter((file) => file.size > MAX_FILE_SIZE);

    if (invalidFiles.length > 0) {
      ToastHandle(
        `Some files exceed the 5MB limit and were not added.`,
        "danger"
      );
      setAttachments([]);
    }

    // Add valid files to attachments
    if (validFiles.length > 0) {
      setAttachments((prevAttachments) => [...prevAttachments, ...validFiles]);
    }
  };

  const handleRemoveFile = (fileName) => {
    setAttachments((prevAttachments) =>
      prevAttachments.filter((file) => file.name !== fileName)
    );
  };

  const handleToggleArchiveStatus = async (emailId) => {
    try {
      const resp = await toggleArchiveStatus(emailId);
      if (
        resp.message === "Email added to archive" ||
        "Email removed from archive"
      ) {
        fetchEmail();
        ToastHandle("Email archive status updated", "success");
      } else {
        ToastHandle("Failed to update archive status", "danger");
      }
    } catch (error) {
      ToastHandle("Failed to update archive status", "danger");
      console.error("Error updating archive status:", error);
    }
  };

  const handleToggleFavoriteStatus = async (emailId) => {
    try {
      await toggleFavoriteStatus(emailId);
      setFavoriteEmails((prevFavorites) => ({
        ...prevFavorites,
        [emailId]: !prevFavorites[emailId],
      }));
      fetchEmail();
      fetchEmailReplies();
      ToastHandle("Email favorite status updated", "success");
    } catch (error) {
      ToastHandle("Failed to update favorite status", "danger");
      console.error("Error updating favorite status:", error);
    }
  };

  const handlePrint = () => {
    window.print();
  };

  const handleMoveToTrash = async (emailId, redirect) => {
    try {
      await moveToTrash(emailId);
      ToastHandle("Email moved to trash", "success");
      if (redirect) {
        navigate("/trash");
      } else {
        fetchEmailReplies();
      }
    } catch (error) {
      ToastHandle("Failed to move email to trash", "success");
      console.error("Error moving email to trash:", error);
    }
  };

  const handleOpenInNewWindow = () => {
    const url = window.location.href;

    const features =
      "width=600,height=400,left=200,top=100,resizable,scrollbars,status";

    window.open(url, "_blank", features);
  };

  const downloadFile = async (url, fileName) => {
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      console.log(blob);

      saveAs(blob, fileName);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };


  const launchEntri = async () => {
    if (typeof window.entri === 'undefined') {
      console.error('Entri SDK not loaded.');
      return;
    }
  
    const domain = 'another.com';
  
    try {

      const response = await verifyUserDomain(domain);

      if (response.status !== 200) {
        throw new Error('Failed to verify domain');
      }
  
      const data = await response.data;
  
      if (data.authToken && data.appId) {
        const config = {
          applicationId: data.appId,
          token: data.authToken,
          // prefilledDomain: domain,
          dnsRecords: data.config.dnsRecords,
        };
  
        window.entri.showEntri(config);
      }
    } catch (error) {
      console.error('Error launching Entri:', error);
    }
  };
  

  return (
    <>
      <div className="bg-white h-full rounded-lg single-inbox-section">
        <div className="bg-white inbox-header border-b flex flex-wrap items-center justify-between px-3 py-1">
          <div className="flex flex-wrap items-center gap-5 relative">
            <button onClick={() => navigate(-1)} className="me-1">
              <TbArrowBackUp className="text-2xl  hover:bg-gray-200 text-gray-500 rounded" />
            </button>
            <button
              className=""
              onClick={() => handleToggleArchiveStatus(email?._id)}
            >
              {email?.archivedBy && email?.archivedBy?.includes(userId) ? (
                <MdArchive size={24} />
              ) : (
                <MdOutlineArchive className="text-2xl hover:bg-gray-200 text-gray-500 rounded" />
              )}
            </button>
            {/* <button className="">
              <MdOutlineReport className="text-2xl  hover:bg-gray-200 text-gray-500 rounded" />
            </button> */}
            <button
              className=""
              onClick={(event) => {
                event.stopPropagation();
                setOpenParentDropdown(false);
                handleMoveToTrash(email?._id, true);
              }}
            >
              <IoTrashOutline className="text-2xl  hover:bg-gray-200 text-gray-500 rounded" />
            </button>
            <button
              className=""
              onClick={() => {
                openParentDropdown
                  ? setOpenParentDropdown(false)
                  : setOpenParentDropdown(true);
              }}
            >
              <HiOutlineDotsVertical className="text-xl hover:bg-gray-200 text-gray-500 rounded" />
            </button>

            {openParentDropdown && (
              <div
                ref={(el) => (dropdownRefs.current[email._id] = el)}
                className="parent-dropdown absolute left-36 z-10 top-6 mt-2 w-fit origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="menu-button"
                tabIndex="-1"
              >
                <div className="py-1" role="none">
                  <li
                    className="rounded-t flex items-center cursor-pointer text-sm font-medium bg-white hover:!bg-[#dae2e8] py-2 px-4 whitespace-no-wrap"
                    onClick={(event) => {
                      event.stopPropagation();
                      setOpenParentDropdown(false);
                      handleMoveToTrash(email?._id, true);
                    }}
                  >
                    <IoTrashOutline className="w-[18px] h-[18px] text-black mr-1" />{" "}
                    Delete
                  </li>
                  <li
                    className="rounded-t flex items-center cursor-pointer w-[200px] text-sm font-medium bg-white hover:!bg-[#dae2e8] py-2 px-4 whitespace-no-wrap"
                    onClick={(event) => {
                      event.stopPropagation();
                      setOpenParentDropdown(false);
                      setTimeout(() => {
                        handlePrint();
                      }, 500);
                    }}
                  >
                    <BiPrinter className="w-[20px] h-[20px] text-black mr-1" />{" "}
                    Print
                  </li>
                  <li
                    className="rounded-t flex items-center cursor-pointer w-[220px] text-sm font-medium bg-white hover:!bg-[#dae2e8] py-2 px-4 whitespace-no-wrap"
                    onClick={(event) => {
                      event.stopPropagation();
                      setOpenParentDropdown(false);
                      handleMarkUnread(email?._id);
                    }}
                  >
                    <BsMailbox className="mr-1 w-[20px] h-[20px]" /> Mark unread
                    from here
                  </li>
                </div>
              </div>
            )}
          </div>
          {/* <div className="flex flex-wrap items-center gap-3">
            <p className="text-gray-500 font-normal text-[14px] hover:bg-gray-200 p-2 rounded">
              1-50 of 51-100
            </p>
            <div className="flex items-center">
              <button>
                <MdOutlineChevronLeft className="text-gray-300 text-xl hover:bg-gray-200 rounded" />
              </button>
              <button>
                <MdOutlineChevronRight className="text-gray-800 text-xl hover:bg-gray-200 rounded" />
              </button>
            </div>
          </div> */}
        </div>
        <div className="single-inbox-body py-5 px-3 md:ps-10 md:pe-5">
          <div className="flex flex-wrap items-center justify-between">
            <h3 className="text-xl font-semibold text-gray-600 mb-3 md:mb-0">
              {email?.subject}
            </h3>
            <div className="flex gap-3">
              <button onClick={handlePrint}>
                <BiPrinter className="text-gray-600 text-xl" />
              </button>
              <button onClick={handleOpenInNewWindow}>
                <RxOpenInNewWindow className="text-gray-600 text-xl" />
              </button>
            </div>
          </div>
          <div className="info py-3 border-b">
            <div className="flex flex-wrap gap-2 items-center justify-between">
              <div className="flex flex-wrap gap-3 items-center">
                <img className="rounded-full" src={User} alt="user" />
                <p className="font-bold text-black">
                  {/* {email?.subject} */}
                  {/* <span className="text-[#5e5e5e] text-[12px] ps-2 font-normal">
                    {email?.to}
                  </span> */}
                  <span className="text-xs text-gray-500 flex items-center font-normal">
                    <BiChevronLeft className="w-[14px] h-[14px] text-gray-500" />
                    {email?.from}
                    <BiChevronRight className="w-[14px] h-[14px] text-gray-500" />
                  </span>

                  <span className="text-xs text-gray-500 flex items-center font-normal break-all">
                    to:{" "}
                    {email?.to?.map((item) => {
                      return `${item},`;
                    })}
                  </span>

                  {email?.cc?.length > 0 && (
                    <span className="text-xs text-gray-500 flex items-center font-normal break-all">
                      cc:{" "}
                      {email?.cc?.map((item) => {
                        return `${item},`;
                      })}
                    </span>
                  )}

                  {email?.bcc?.length > 0 &&
                  (email?.bcc?.includes(userData?.email) ||
                    email?.from === userData?.email) ? (
                    <span className="text-xs text-gray-500 flex items-center font-normal break-all">
                      bcc:{" "}
                      {email?.bcc?.map((item) => {
                        return `${item},`;
                      })}
                    </span>
                  ) : (
                    ""
                  )}
                </p>
              </div>
              <div className="flex gap-3">
                <p className="text-gray-600 text-base">
                  {/* Jul 24, 2024, 7:40PM (2 days ago) */}
                  {displayDate}
                </p>
                <button onClick={() => handleToggleFavoriteStatus(email?._id)}>
                  {/* <IoMdStarOutline className="text-2xl text-gray-600" /> */}
                  <IoMdStarOutline
                    className={`text-2xl ${
                      email?.starredBy && email?.starredBy?.includes(userId)
                        ? "text-yellow-500"
                        : "text-gray-400"
                    }   hidden md:block `}
                  />
                </button>
                <button
                  onClick={() => {
                    setHandleShowReply(!handleShowReply);
                    setBody("");
                    if (!handleShowReply === true) {
                      setTimeout(() => {
                        window.scrollTo({
                          top: document.documentElement.scrollHeight,
                          behavior: "smooth",
                        });
                      }, 50);
                    }
                  }}
                >
                  {/* <TbArrowBackUp className="text-2xl text-gray-600" /> */}
                  <GoReply className="text-2xl text-gray-600" />
                </button>
              </div>
            </div>
            {/* <div className="inbox-img border px-3 rounded-md mt-3 py-2 flex flex-wrap items-center gap-2">
              <img src={Imagebox} />
              <p className="font-bold text-gray-700 text-[15px]">
                Images are not displayed.
              </p>
              <p className="font-normal text-[#2a79bd] text-[13px]">
                Display images below
              </p>
            </div> */}
          </div>
          <div className="py-8 px-0 md:px-5">
            <div
              className="font-normal text-[12px] text-gray-600"
              dangerouslySetInnerHTML={{ __html: email?.body }}
            />
          </div>

          {email?.attachments?.length > 0 && (
            <div className="flex items-center">
              {email?.attachments?.map((file, i) => {
                const fileExtension = file.split(".").pop().toLowerCase();

                const fileName = `attachment-${i}.${fileExtension}`;

                if (
                  ["jpg", "jpeg", "png", "gif", "bmp", "webp"].includes(
                    fileExtension
                  )
                ) {
                  return (
                    <div className="mb-4 relative w-fit mr-6" key={i}>
                      <FaFileImage size={80} />

                      <div
                        className="absolute w-full h-full top-[30%] left-[20px]"
                        onClick={() => downloadFile(file, fileName)}
                      >
                        <MdDownload
                          size={40}
                          className="text-[#d4d4d4] cursor-pointer"
                        />
                      </div>
                      {file?.length > 20 ? (
                        <span>
                          {file?.slice(55, 70)}...{fileExtension}
                        </span>
                      ) : (
                        <span>{file}</span>
                      )}
                    </div>
                  );
                } else if (fileExtension === "pdf") {
                  return (
                    <div className="mb-4 relative w-fit mr-6" key={i}>
                      <FaFilePdf color="red" size={80} />
                      <div
                        className="absolute w-full h-full top-[30%] left-[20px]"
                        onClick={() => downloadFile(file, fileName)}
                      >
                        <MdDownload
                          size={40}
                          className="text-[#d4d4d4] cursor-pointer"
                        />
                      </div>
                      {file?.length > 20 ? (
                        <span>
                          {file?.slice(55, 70)}...{fileExtension}
                        </span>
                      ) : (
                        <span>{file}</span>
                      )}
                    </div>
                  );
                } else {
                  return (
                    <div className="mb-4 relative w-fit mr-6" key={i}>
                      <FaFile size={80} className="text-[#65b1f3]" />
                      <div
                        className="absolute w-full h-full top-[30%] left-[20px]"
                        onClick={() => downloadFile(file, fileName)}
                      >
                        <MdDownload
                          size={40}
                          className="text-[#d4d4d4] cursor-pointer"
                        />
                      </div>

                      {file?.length > 20 ? (
                        <span>
                          {file?.slice(55, 70)}...{fileExtension}
                        </span>
                      ) : (
                        <span>{file}</span>
                      )}
                    </div>
                  );
                }
              })}
            </div>
          )}

{/* <button type="button" onClick={launchEntri}>
    Launch Entri
</button> */}

          {replies?.map((item) => {
            return (
              <div key={item?._id} className="mt-4">
                <div className="flex flex-wrap gap-2 items-center justify-between mb-4">
                  <div className="flex items-center flex-wrap gap-2">
                    <div>
                      <img
                        src={User}
                        className="w-[50px] bg-[#eee] rounded-full p-2"
                        alt="profile"
                      />
                    </div>
                    <div>
                      <div className="flex items-center flex-wrap">
                        <h3 className="text-sm font-semibold text-gray-700 mb-0 capitalize">
                          {item?.userName}
                        </h3>
                        <span className="text-xs text-gray-500 flex items-center">
                          <BiChevronLeft className="w-[14px] h-[14px] text-gray-500" />
                          {item?.from}
                          <BiChevronRight className="w-[14px] h-[14px] text-gray-500" />
                        </span>
                      </div>

                      <span className="text-xs text-gray-500 flex items-center">
                        to:{" "}
                        {item?.to?.map((item) => {
                          return `${item},`;
                        })}
                      </span>

                      {item?.cc?.length > 0 && item?.cc[0] !== "" && (
                        <span className="text-xs text-gray-500 flex items-center font-normal">
                          cc:{" "}
                          {item?.cc?.map((item) => {
                            return `${item},`;
                          })}
                        </span>
                      )}

                      {item?.bcc?.length > 0 &&
                      item?.bcc[0] !== "" &&
                      (item?.bcc?.includes(userData?.email) ||
                        item?.from === userData?.email) ? (
                        <span className="text-xs text-gray-500 flex items-center font-normal">
                          bcc:{" "}
                          {item?.bcc?.map((item) => {
                            return `${item},`;
                          })}
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="flex items-center gap-2">
                    <button>
                      {/* <p className="text-sm text-black mb-0">
                          {moment(item?.updatedAt).format("MMMM D")}
                        </p> */}
                    </button>
                    <button
                      onClick={(event) => {
                        event.stopPropagation();
                        handleToggleFavoriteStatus(item?._id);
                      }}
                      className={`text-2xl ${
                        item?.starredBy && item?.starredBy?.includes(userId)
                          ? "text-yellow-500"
                          : "text-gray-500"
                      }`}
                    >
                      <IoMdStarOutline />
                    </button>
                    <button
                      onClick={() => {
                        setHandleShowReply(!handleShowReply);
                        setBody("");
                        if (!handleShowReply === true) {
                          setTimeout(() => {
                            window.scrollTo({
                              top: document.documentElement.scrollHeight,
                              behavior: "smooth",
                            });
                          }, 50);
                        }
                      }}
                    >
                      <GoReply className="w-[20px] h-[20px] text-black" />
                    </button>
                    {/* <button><MdMoreVert className="w-[20px] h-[20px] text-black" /></button> */}
                    <div className="relative inline-block text-left">
                      <div>
                        <button
                          onClick={(event) => {
                            event.stopPropagation(); // Prevent click event from reaching document
                            // setIsMenuOpen((prev) => !prev);
                            // handleToggleFavoriteStatus(item?._id);
                            handleDropdownToggle(item._id);
                          }}
                          className="text-gray-700 font-semibold py-2 rounded inline-flex items-center"
                        >
                          <span className="mr-1">
                            <MdOutlineMoreVert className="w-[20px] h-[20px] text-black" />
                          </span>
                        </button>
                      </div>
                      {openDropdownId === item?._id && (
                        <div
                          ref={(el) => (dropdownRefs.current[item._id] = el)}
                          className="reply-dropdown absolute right-0 z-10 mt-2 w-fit origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                          role="menu"
                          aria-orientation="vertical"
                          aria-labelledby="menu-button"
                          tabIndex="-1"
                        >
                          <div className="py-1" role="none">
                            <li
                              className="rounded-t flex items-center cursor-pointer text-sm font-medium bg-white hover:!bg-[#dae2e8] py-2 px-4 whitespace-no-wrap"
                              onClick={(event) => {
                                event.stopPropagation();
                                handleMoveToTrash(item?._id, false);
                              }}
                            >
                              <IoTrashOutline className="w-[18px] h-[18px] text-black mr-1" />{" "}
                              Delete
                            </li>
                            <a
                              className="flex items-center text-black rounded-t cursor-pointer w-[200px] text-sm font-medium bg-white hover:!bg-[#dae2e8] py-2 px-4 whitespace-no-wrap"
                              href={`/singleinbox/${item?._id}?print=true`}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <BiPrinter className="w-[20px] h-[20px] text-black mr-1" />{" "}
                              Print
                            </a>
                            <li
                              className="rounded-t flex items-center cursor-pointer w-[220px] text-sm font-medium bg-white hover:!bg-[#dae2e8] py-2 px-4 whitespace-no-wrap"
                              onClick={(event) => {
                                event.stopPropagation();
                                handleMarkUnread(item?._id);
                              }}
                            >
                              <BsMailbox className="mr-1 w-[20px] h-[20px]" />{" "}
                              Mark unread from here
                            </li>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="bg-[#f0f9ff] p-5 md:p-10">
                  <div className="mb-4">
                    {/* <h3 className="md:text-[20px] text-base font-semibold text-gray-700 mb-2 md:mb-4">{item?.subject}</h3> */}
                    <p
                      className="text-sm font-normal text-gray-700 mb-2"
                      dangerouslySetInnerHTML={{ __html: item?.body }}
                    />
                  </div>

                  {item?.attachments?.length > 0 && (
                    <div className="flex items-center">
                      {item?.attachments?.map((attachment, i) => {
                        const fileExtension = attachment
                          .split(".")
                          .pop()
                          .toLowerCase();

                        const fileName = `attachment-${i}.${fileExtension}`;

                        if (
                          ["jpg", "jpeg", "png", "gif", "bmp", "webp"].includes(
                            fileExtension
                          )
                        ) {
                          return (
                            <div className="mb-4 relative w-fit mr-6" key={i}>
                              {/* <img
                                  src={attachment}
                                  alt="attachment"
                                  className="max-w-full h-auto"
                                /> */}
                              <FaFileImage size={80} />

                              <div
                                className="absolute w-full h-full top-[30%] left-[20px]"
                                onClick={() =>
                                  downloadFile(attachment, fileName)
                                }
                              >
                                <MdDownload
                                  size={40}
                                  className="text-[#d4d4d4] cursor-pointer"
                                />
                              </div>
                              {attachment?.length > 20 ? (
                                <span>
                                  {attachment?.slice(55, 70)}...{fileExtension}
                                </span>
                              ) : (
                                <span>{attachment}</span>
                              )}
                            </div>
                          );
                        } else if (fileExtension === "pdf") {
                          return (
                            <div className="mb-4 relative w-fit mr-6" key={i}>
                              <FaFilePdf color="red" size={80} />
                              {/* <a
                                  href={attachment}
                                  download={`attachment-${i}.pdf`}
                                  className="text-blue-500"
                                ></a> */}
                              <div
                                className="absolute w-full h-full top-[30%] left-[20px]"
                                onClick={() =>
                                  downloadFile(attachment, fileName)
                                }
                              >
                                <MdDownload
                                  size={40}
                                  className="text-[#d4d4d4] cursor-pointer"
                                />
                              </div>
                              {attachment?.length > 20 ? (
                                <span>
                                  {attachment?.slice(55, 70)}...{fileExtension}
                                </span>
                              ) : (
                                <span>{attachment}</span>
                              )}
                            </div>
                          );
                        } else {
                          return (
                            <div className="mb-4 relative w-fit mr-6" key={i}>
                              {/* <a
                                  href={attachment}
                                  download={`attachment-${i}`}
                                  className="text-blue-500"
                                >
                                </a> */}
                              <FaFile size={80} className="text-[#65b1f3]" />
                              <div
                                className="absolute w-full h-full top-[30%] left-[20px]"
                                onClick={() =>
                                  downloadFile(attachment, fileName)
                                }
                              >
                                <MdDownload
                                  size={40}
                                  className="text-[#d4d4d4] cursor-pointer"
                                />
                              </div>

                              {attachment?.length > 20 ? (
                                <span>
                                  {attachment?.slice(55, 70)}...{fileExtension}
                                </span>
                              ) : (
                                <span>{attachment}</span>
                              )}
                            </div>
                          );
                        }
                      })}
                    </div>
                  )}
                </div>
              </div>
            );
          })}
          {handleShowReply && (
            <div
              className="mt-10 rounded-xl p-2"
              style={{
                boxShadow:
                  "0 1px 2px 0 rgba(60,64,67,.3),0 2px 6px 2px rgba(60,64,67,.15)",
              }}
            >
              <div>
                <div className="text-sm text-gray-500 flex items-center m-2 break-all">
                  <BiReply className="mr-1" />
                  <span>To:</span>
                  <div className="flex flex-wrap items-center">
                    {/* If ccEmails or bccEmails exist, show 'From' email in 'To' */}
                    {ccEmails.length > 0 || bccEmails.length > 0 ? (
                      <span className="mr-2">
                        {email?.from !== userData?.email ? email?.from : email.to}
                        <button
                          onClick={() => handleRemoveEmail("to", 0)}
                          className="ml-1 text-red-500"
                        >
                          ×
                        </button>
                      </span>
                    ) : (
                      toEmails.map((item, index) => (
                        <span key={index} className="mr-2">
                          {item === userData?.email ? email?.from : item}
                          <button
                            onClick={() => handleRemoveEmail("to", index)}
                            className="ml-1 text-red-500"
                          >
                            ×
                          </button>
                        </span>
                      ))
                    )}
                    <input
                      type="email"
                      placeholder=""
                      className="rounded p-1"
                      onKeyDown={(e) => handleAddEmail("to", e)}
                    />
                  </div>
                </div>

                {/* CC Emails (shift 'To' emails into 'CC' if 'CC' or 'BCC' exists) */}
                {(ccEmails.length > 0 || bccEmails.length > 0) && (
                  <div className="text-sm text-gray-500 flex items-center m-2 break-all">
                    <span>CC:</span>
                    <div className="flex flex-wrap items-center">
                      {/* Add 'To' emails to 'CC' */}
                      {toEmails.filter((item) => item !== email.from).map((item, index) => (
                        <span key={index} className="mr-2">
                          {item !== userData?.email && email?.from !== userData?.email &&  (
                            <>
                              {item}
                              <button
                                onClick={() => handleRemoveEmail("cc", index)}
                                className="ml-1 text-red-500"
                              >
                                ×
                              </button>
                            </>
                          )}
                        </span>
                      ))}
                      {/* Also render the existing CC emails */}
                      {ccEmails.map((item, index) => (
                        <span key={index} className="mr-2">
                          {item !== userData?.email && (
                            <>
                              {item}
                              <button
                                onClick={() => handleRemoveEmail("cc", index)}
                                className="ml-1 text-red-500"
                              >
                                ×
                              </button>
                            </>
                          )}
                        </span>
                      ))}
                      <input
                        type="email"
                        placeholder=""
                        className="rounded p-1"
                        onKeyDown={(e) => handleAddEmail("cc", e)}
                      />
                    </div>
                  </div>
                )}

                {/* {bccEmails.length > 0 &&
                  (bccEmails.includes(userData?.email) ||
                    email?.from === userData?.email) &&
                  (bccEmails.length !== 1 ||
                    (bccEmails.length === 1 &&
                      !bccEmails.includes(userData?.email))) && (
                    <div className="text-sm text-gray-500 flex items-center m-2 break-all">
                      <span>BCC:</span>
                      <div className="flex flex-wrap items-center">
                        {bccEmails.map((item, index) => (
                          <span key={index} className="mr-2">
                            {item !== userData?.email && (
                              <>
                                {item}
                                <button
                                  onClick={() =>
                                    handleRemoveEmail("bcc", index)
                                  }
                                  className="ml-1 text-red-500"
                                >
                                  ×
                                </button>
                              </>
                            )}
                          </span>
                        ))}
                        <input
                          type="email"
                          placeholder=""
                          className="rounded p-1"
                          onKeyDown={(e) => handleAddEmail("bcc", e)}
                        />
                      </div>
                    </div>
                  )} */}
              </div>
              {/* <span className="text-sm text-gray-500 flex items-center m-2 break-all">
                <BiReply className="mr-1" />
                to:{" "}
                {email?.to?.map((item) => {
                  return `${item},`;
                })}
                {email?.cc?.length > 0 && (
                  <span className="text-sm text-gray-500 flex items-center font-normal">
                    cc:{" "}
                    {email?.cc?.map((item) => {
                      return `${item},`;
                    })}
                  </span>
                )}
                {email?.bcc?.length > 0 &&
                (email?.bcc?.includes(userData?.email) ||
                  email?.from === userData?.email) ? (
                  <span className="text-sm text-gray-500 flex items-center font-normal">
                    bcc:{" "}
                    {email?.bcc?.map((item) => {
                      return `${item},`;
                    })}
                  </span>
                ) : (
                  ""
                )}
              </span> */}
              <ReactQuill
                value={body}
                onChange={handleReplyInput}
                modules={modules}
                formats={formats}
                bounds={".app"}
                placeholder="Write something..."
                // onImageUpload={handleImageUpload}
                // style={{ height: '250px' }}
              />

              {attachments?.length > 0 && (
                <div>
                  {attachments?.map((attachment, i) => {
                    return (
                      <div
                        className="flex items-center bg-[#f5f5f5] w-fit px-2 mt-2"
                        key={i}
                      >
                        <p className="mb-0">{attachment.name}</p>
                        <RxCross2
                          className="w-[16px] h-[16px] font-bold mt-1 ml-3 cursor-pointer rounded-sm"
                          onClick={() => handleRemoveFile(attachment.name)}
                        />
                      </div>
                    );
                  })}
                </div>
              )}
              <div className="flex items-center justify-between">
                <div className="flex items-center mt-2">
                  <button
                    onClick={() => fileInputRef.current.click()}
                    className="flex items-center space-x-2 mr-2"
                  >
                    <IoAttach className="w-[20px] h-[20px] text-black" />
                    <input
                      type="file"
                      ref={fileInputRef}
                      className="hidden"
                      multiple
                      onChange={handleFileChange}
                    />
                  </button>

                  <button
                    className="flex items-center bg-[#2a79bd] text-white  py-2 px-4 rounded-md"
                    onClick={handleEmailReply}
                    disabled={loading}
                  >
                    {loading ? "Sending..." : "Send"}
                  </button>
                </div>
                <IoTrashOutline
                  className="mt-2 mr-2 w-[18px] h-[18px] cursor-pointer"
                  onClick={() => {
                    setHandleShowReply(false);
                    setBody("");
                  }}
                />
              </div>
            </div>
          )}
        </div>
        <div className="single-inbox-footer px-[20px] pb-5">
          <div className="flex gap-4 items-center justify-end">
            {!handleShowReply && (
              <button
                className="text-base flex border py-2 px-3 gap-1 rounded-md items-center text-white bg-[#2a79bd]"
                onClick={() => {
                  setHandleShowReply(!handleShowReply);
                  setBody("");
                  if (!handleShowReply === true) {
                    setTimeout(() => {
                      window.scrollTo({
                        top: document.documentElement.scrollHeight,
                        behavior: "smooth",
                      });
                    }, 50);
                  }
                }}
              >
                <BsReply className="text-xl" />
                Reply
              </button>
            )}
            {/* <button className="text-base flex border py-2 px-3 gap-1 rounded-md items-center text-white bg-[#2a79bd]">
              <TiArrowForwardOutline className="text-xl" />
              Forward
            </button> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default SingleInbox;
