import React, { useEffect, useState } from "react";
import LoginImage from "../../../../src/images/login-images.jpg";
import "./style.css";
import { Link } from "react-router-dom";
import ToastHandle from "../../helpers/ToastMessage";
import { useNavigate } from "react-router-dom";
import { isAuthenticated } from "../../helpers/AuthConditionCheck";
import { ButtonLoading } from "../../helpers/MainLoader";
import { userLogin } from "../../../Api/ExportApi";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa6";
import { useAppContext } from "../../../context/AppContext";

const Login = () => {
  const authLocation = isAuthenticated();
  const navigate = useNavigate();
  const { login } = useAppContext();
  const [formInput, setFormInput] = useState({ email: "", password: "" });
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const inputOnchange = (e) => {
    const { name, value } = e.target;
    setFormInput({ ...formInput, [name]: value });
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    const { email, password } = formInput;
    if (email && password) {
      try {
        const response = await userLogin(email, password);
        if (response?.status === 200) {
          const loginStatus = response?.data?.status;
          const loginMessage = response?.data?.message;
          const token = response?.data?.token;
          localStorage.setItem("token", token);

          login(response?.data?.user, token, response?.data?.user?.role);
          ToastHandle(loginMessage, "success");
          setTimeout(() => {
            navigate("/");
          }, 1000);
        }
      } catch (error) {
        ToastHandle(
          error?.response?.data?.message || "Something went wrong!",
          "danger"
        );
      }
    } else {
      ToastHandle(
        `Please Enter Your ${!email ? "Email" : "Password"}`,
        "danger"
      );
    }
    setLoading(false);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    if (authLocation) {
      navigate("/");
    } else {
      navigate("/login");
    }
  }, []);
  return (
    <>
      <div className="banner flex justify-center items-center flex-wrap pt-10 md:py-0">
        <div className="w-full md:w-3/4 lg:w-1/2 px-[0] 2xl:px-[150px] mx-auto mb-4 lg:mb-0 ">
          <h1 className="md:px-10 px-5 font-semibold text-4xl mb-8 w-full text-center">
            Login
          </h1>
          {/*<p className="md:px-10 px-5 mb-8 text-[#333] text-base	leading-[28px] font-medium">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy
          </p>*/}
          <form className="space-y-6 md:px-10 px-5" action="#" method="POST">
            <div>
              <label
                for="email"
                className="block text-sm font-medium leading-4 text-gray-900"
              >
                Email
              </label>
              <div className="mt-2">
                <input
                  id="email"
                  name="email"
                  type="email"
                  onChange={(e) => inputOnchange(e)}
                  autocomplete="email"
                  required
                  placeholder="Enter your Email"
                  className="block w-full rounded-md border-0 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#008bfb] sm:text-sm sm:leading-6 px-3"
                />
              </div>
            </div>

            <div>
              <div className="flex items-center justify-between">
                <label
                  for="password"
                  className="block text-sm font-medium leading-4 text-gray-900"
                >
                  Password
                </label>
              </div>
              <div className="mt-2">
                <div className="relative">
                  <input
                    id="password"
                    name="password"
                    onChange={(e) => inputOnchange(e)}
                    type={showPassword ? "text" : "password"}
                    autocomplete="current-password"
                    placeholder="Enter your password"
                    required
                    className="block w-full rounded-md border-0 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#008bfb] sm:text-sm sm:leading-6 px-3"
                  />

                  {showPassword ? (
                    <FaRegEyeSlash
                      color="gray"
                      size={20}
                      onClick={togglePasswordVisibility}
                      className="absolute right-3 top-[30%] cursor-pointer"
                    />
                  ) : (
                    <FaRegEye
                      color="gray"
                      size={20}
                      onClick={togglePasswordVisibility}
                      className="absolute right-3 top-[30%] cursor-pointer"
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="text-sm text-end">
              <Link
                to="/forgot-password"
                className="font-semibold text-[#2a79bd] hover:opacity-75"
              >
                Forgot password?
              </Link>
            </div>

            <div>
              <button
                type="submit"
                className="flex w-full justify-center rounded-lg bg-[#2a79bd] px-3 py-3 text-sm font-semibold leading-6 text-white shadow-sm hover:opacity-75 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                onClick={handleLogin}
                disabled={loading ? true : false}
              >
                {!loading ? "Login" : <ButtonLoading />}
              </button>
              <p className="text-sm text-black text-center mt-2 mb-0">
                Don't have an account?{" "}
                <Link
                  to="/register"
                  className="font-medium text-[#2a79bd] underline"
                >
                  Register
                </Link>
              </p>
            </div>
          </form>
        </div>

        <div className="w-full md:w-1/2 lg:w-1/2 flex items-start lg:items-center right-section">
          <img className="p-10" src={LoginImage} alt="login" />
        </div>
      </div>
    </>
  );
};

export default Login;
