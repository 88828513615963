import { FaRegTrashCan } from "react-icons/fa6";
import { RiInboxFill } from "react-icons/ri";
import { IoPaperPlaneSharp } from "react-icons/io5";
import { FaRegStar } from "react-icons/fa";
import { IoMdArchive } from "react-icons/io";
import { CiCreditCard1, CiFileOn } from "react-icons/ci";
import { MdAlternateEmail, MdOutlineMail } from "react-icons/md";

export const navLinks = [
  // {
  //   label: "Compose Email",
  //   icon: <RiEdit2Fill />,
  //   link: "/",
  // },
  {
    label: "Inbox",
    icon: <RiInboxFill />,
    link: "/inbox",
  },
  {
    label: "Starred",
    link: "/starred",
    icon: <FaRegStar />,
  },
  {
    label: "Archived",
    link: "/archived",
    icon: <IoMdArchive />,
  },
  {
    label: "Drafts",
    link: "/drafts",
    icon: <CiFileOn />,
  },
  {
    label: "Sent Items",
    icon: <IoPaperPlaneSharp />,
    link: "/sentitem",
  },
  {
    label: "Plans",
    icon: <CiCreditCard1  />,
    link: "/plans",
    requiresAdmin: true,
  },
  {
    label: "Domains",
    icon: <MdAlternateEmail />,
    link: "/domains",
    requiresAdmin: true,
  },
  {
    label: "Custom Email",
    icon: <MdOutlineMail />,
    link: "/custom-email",
    requiresAdmin: true,
  },
  {
    label: "Trash",
    icon: <FaRegTrashCan />,
    link: "/trash",
  },
];
