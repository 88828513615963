import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  createSmtpUser,
  deleteSmtpUser,
  getDomainsByUser,
  getUserCustomEmails,
  getVerifiedDomainsByUser,
  setDefaultEmail
} from "../../Api/ExportApi";
import { jwtDecode } from "jwt-decode";
import ToastHandle from "../../Components/helpers/ToastMessage";
import { FaEye, FaEyeSlash, FaRegTrashAlt } from "react-icons/fa";
import { RxCross2 } from "react-icons/rx";
import ConfirmModal from "../../Components/ConfirmModal";
import { useAppContext } from "../../context/AppContext";

const CustomEmail = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [userId, setUserId] = useState(null);
  const [domain, setDomain] = useState("");
  const [password, setPassword] = useState("");
  const [lastName, setLastName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [userDomains, setUserDomains] = useState([]);
  const [userCustomEmails, setUserCustomEmails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [email, setEmail] = useState("");
  const [emailId, setEmailId] = useState("");
  const [userData, setUserData] = useState({});

  const [verifiedDomains, setVerifiedDomains] = useState([]);
  const [visiblePasswords, setVisiblePasswords] = useState({});

  const { user, loading: userLoading } = useAppContext();

  const getDomains = async () => {
    try {
      const resp = await getDomainsByUser();
      if (resp.status === 200) {
        setUserDomains(resp?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchUserData = async () => {
    const token = localStorage.getItem("token");
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        const userId = decodedToken.userId;
        setUserId(userId);
        setUserData(user);
      } catch (err) {
        console.error("Error fetching user data:", err.message);
      }
    } else {
      console.log("No token found");
    }
  };

  const getAllCustomEmails = async () => {
    try {
      const resp = await getUserCustomEmails();
      setUserCustomEmails(resp?.data);
    } catch (err) {
      console.error("Error fetching user data:", err.message);
    }
  };

  useEffect(() => {
    if (!userLoading) {
      fetchUserData();
    }
  }, [userLoading]);

  useEffect(() => {
    getDomains();
    getAllCustomEmails();
  }, []);

  const getVerifiedDomains = async () => {
    const resp = await getVerifiedDomainsByUser();
    setVerifiedDomains(resp?.data);
  };

  useEffect(() => {
    getVerifiedDomains();
  }, [currentPage]);

  const handleAddCustomEmail = async () => {
    setLoading(true);
    try {
      const emailWithDomain = email + "@" + domain;
      const resp = await createSmtpUser(
        emailWithDomain,
        domain,
        password,
        firstName,
        lastName
      );

      if (resp.status === 201) {
        ToastHandle("Email created successfully", "success");
        fetchUserData();
        setLoading(false);
        setModal(false);
        getAllCustomEmails();
        setEmail("");
        setDomain("");
        setPassword("");
        setFirstName("");
        setLastName("");
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      ToastHandle(error?.response?.data?.message, "danger");
    }
  };

  const handleDeleteUser = async () => {
    try {
      const resp = await deleteSmtpUser(emailId);

      if (resp.status === 200) {
        ToastHandle("Email deleted Successfully", "success");
        getAllCustomEmails();
        setConfirmModal(false);
      }
    } catch (error) {
      console.log(error);
      ToastHandle(error?.response?.data?.message, "danger");
    }
  };

  const handleSetDefaultEmail = async (email) => {
    try {
      const response = await setDefaultEmail(email);
      if (response?.status === 200) {
        ToastHandle("Default email set successfully", "success");
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        ToastHandle("Something went wrong", "danger");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const togglePasswordVisibility = (email) => {
    setVisiblePasswords((prev) => ({
      ...prev,
      [email]: !prev[email],
    }));
  };

  const frontNameRegex = /^[a-zA-Z0-9._%+-]+$/;

  return (
    <div className="relative">
      {/* handleDeleteUser(item?.email, domain) */}
      {confirmModal && (
        <ConfirmModal
          handleConfirmModal={setConfirmModal}
          handleDeleteUser={handleDeleteUser}
        />
      )}
      <div className="py-2">
        <div className="inbox-body border-t ">
          <div className="inbox-body-inner">
            <div className="flex flex-col">
              <div className="overflow-x-auto sm:mx-0.5 lg:mx-0.5">
                <div className="py-2 min-w-full">
                  <div className="overflow-hidden">
                    <div className="flex justify-between items-center my-5">
                      <h2 className="text-3xl font-bold">
                        Create Custom Email
                      </h2>
                      <button
                        type="button"
                        className="text-white bg-blue-700 hover:bg-blue-800 disabled:bg-blue-400 disabled:hover:bg-blue-400 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                        onClick={() => setModal(true)}
                      >
                        Create custom email
                      </button>
                    </div>
                    <div className="sm:overflow-x-hidden overflow-x-scroll">
                      <table className="min-w-full">
                        <thead className="bg-white border-b">
                          <tr>
                            <th
                              scope="col"
                              className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                            >
                              #
                            </th>
                            <th
                              scope="col"
                              className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                            >
                              Name
                            </th>
                            <th
                              scope="col"
                              className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                            >
                              Custom Email
                            </th>
                            <th
                              scope="col"
                              className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                            >
                              Password
                            </th>
                            {/* <th
                            scope="col"
                            className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                          >
                            Default
                          </th> */}
                            <th
                              scope="col"
                              className="text-sm font-medium text-gray-900 px-6 py-4"
                            >
                              Action
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {userData && (
                            <>
                              {userCustomEmails?.map((item, i) => (
                                <tr
                                  className="bg-gray-100 border-b"
                                  key={item?._id}
                                >
                                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                    {i + 1}
                                  </td>
                                  <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                    {item?.firstName} {item?.lastName}
                                  </td>
                                  <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                    {item?.email}
                                  </td>
                                  <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                    {item?.password && (
                                      <>
                                        {visiblePasswords[item?.email] ? (
                                          <span>{item?.password}</span>
                                        ) : (
                                          <span>••••••••</span>
                                        )}
                                        <button
                                          className="ml-2"
                                          onClick={() =>
                                            togglePasswordVisibility(
                                              item?.email
                                            )
                                          }
                                        >
                                          {visiblePasswords[item?.email] ? (
                                            <FaEyeSlash />
                                          ) : (
                                            <FaEye />
                                          )}
                                        </button>
                                      </>
                                    )}
                                  </td>
                                  {/* <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                  {userData?.user?.email === item?.email ? (
                                    <FaCheckCircle
                                      size={20}
                                      className="cursor-pointer text-green-500"
                                    />
                                  ) : (
                                    <FaRegCircle
                                      size={20}
                                      className="cursor-pointer"
                                      onClick={() =>
                                        handleSetDefaultEmail(item?.email)
                                      }
                                    />
                                  )}
                                </td> */}
                                  <td className="flex justify-center mt-3">
                                    <FaRegTrashAlt
                                      className="cursor-pointer"
                                      color="red"
                                      onClick={() => {
                                        setConfirmModal(true);
                                        setEmailId(item?.email);
                                      }}
                                    />
                                  </td>
                                </tr>
                              ))}
                            </>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {modal && (
        <div className="fixed top-0 left-0 bg-[#00000073] w-full h-full flex justify-center items-center">
          <div className="bg-white w-[90%] sm:w-[40%] h-fit pb-5 rounded-xl pt-8 relative">
            <RxCross2
              size={30}
              className="absolute sm:top-5 top-2 sm:right-5 right-2 cursor-pointer"
              onClick={() => {
                setModal(false);
                setDomain("");
              }}
            />
            <h1 className="sm:text-4xl text-2xl font-bold text-center mb-10">
              Create Custom Email
            </h1>
            <div className="flex flex-col justify-center items-center mt-16">
              <div className="sm:w-[50%] w-[90%]">
                <div className="flex items-center mb-7">
                  <input
                    id="email"
                    name="email"
                    type="text"
                    autoComplete="off"
                    placeholder="Enter your new email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    className="block w-full rounded-md border-0 py-2.5 text-gray-900 shadow-sm ring-1 rounded-tr-none rounded-br-none ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#008bfb] sm:text-sm sm:leading-6 px-3"
                  />
                  <div className="bg-gray-200 p-2.5 rounded-md rounded-tl-none rounded-bl-none">
                    {/* <p>@{domain}</p> */}
                    <select
                      className="bg-transparent"
                      onChange={(e) => setDomain(e.target.value)}
                    >
                      <option value="">Select domain</option>
                      {verifiedDomains?.map((item) => (
                        <option key={item?._id} value={item?.domain}>
                          @{item?.domain}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                {domain && (
                  <>
                    <div className="flex items-center mb-6">
                      <input
                        id="firstName"
                        name="firstName"
                        type="text"
                        autoComplete="off"
                        placeholder="Enter first name"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        required
                        className="block w-full rounded-md border-0 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#008bfb] sm:text-sm sm:leading-6 px-3"
                      />
                    </div>

                    <div className="flex items-center mb-6">
                      <input
                        id="lastName"
                        name="lastName"
                        type="text"
                        autoComplete="off"
                        placeholder="Enter last name"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        required
                        className="block w-full rounded-md border-0 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#008bfb] sm:text-sm sm:leading-6 px-3"
                      />
                    </div>

                    <div className="flex items-center mb-10">
                      <input
                        id="passowrd"
                        name="passowrd"
                        type="text"
                        autoComplete="off"
                        placeholder="Enter password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                        className="block w-full rounded-md border-0 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#008bfb] sm:text-sm sm:leading-6 px-3"
                      />
                    </div>
                  </>
                )}
              </div>

              {/* <input
                id="email"
                name="customEmail"
                type="text"
                autoComplete="off"
                placeholder="Enter email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="relative block w-[60%] mb-5 rounded-md border-0 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#008bfb] sm:text-sm sm:leading-6 px-3"
              /> */}

              <button
                type="button"
                className="text-white bg-blue-700 hover:bg-blue-800 disabled:bg-blue-400 disabled:hover:bg-blue-400 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-10 py-3.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                onClick={handleAddCustomEmail}
                disabled={
                  loading ||
                  domain?.trim() === "" ||
                  email?.trim() === "" ||
                  password?.trim() === "" ||
                  firstName?.trim() === "" ||
                  lastName?.trim() === "" ||
                  !frontNameRegex.test(email)
                }
              >
                {loading ? "Loading..." : "Create Email Account"}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomEmail;
